.register {
    background-color: whitesmoke;
    min-height: calc(100vh - 70px);

    .container {
        display: flex;
        flex-direction: column;
        padding: 30px;
        gap: 30px;

        .title {
            font-size: 20px;
        }
        .desc {
            font-weight: 500;
        }
        .content {
            display: flex;
            flex-direction: column;
            position: relative;
            gap: 20px;
            textarea {
                width: 90%;
                height: 100px;
                padding: 20px;
                font-size: 15px;
            }
            .error {
                position: absolute;
                bottom: 70px;
                color: red;
                font-size: 17px;
            }
            .buttons {
                width: 90%;
                padding: 20px;
                display: flex;
                justify-content: flex-end;
                .btnSubmit {
                    padding: 10px 20px;
                    border-radius: 12px;
                    color: white;
                    font-weight: 600;
                    color: white;
                    background-color: #009900;
                    &:hover {
                        background-color: #02a202;
                        cursor: pointer;
                    }
                }
                .clicked {
                    background-color: gray;
                    &:hover {
                        cursor: default;
                        background-color: gray;
                    }
                }
            }
        }
    }
}