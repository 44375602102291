.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    position: sticky;
    padding: 10px 20px;
    top: 0;
    z-index: 999;
    border-bottom: 1px solid lightgray;
    background-color: white;

    .title {
        display: flex;
        align-items: center;
        gap: 10px;
        h1 {
            color: #009900;
            font-size: 30px;
        }
        span {
            color: blue;
            font-size: 25px;
            font-weight: 600;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .btnSubmit {
        padding: 15px;
        font-size: 15px;
        border-radius: 14px;
        font-weight: 600;
        &:hover {
            cursor: pointer;
            background-color: lightgray;
        }
    }
}