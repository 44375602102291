.home {
    background-color: whitesmoke;
    min-height: calc(100vh - 70px);

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
    }
}