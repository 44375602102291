
.login {
    height: 100vh;
    display: flex;
    align-items: center;

    .container {
        display: flex;
        flex: 0.3;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 20px 30px;
        margin: 0 auto;
        border: 2px solid lightgray;    
        background-color: whitesmoke;
        position: relative;

        .title {
            font-size: 30px;
            font-weight: 600;
            color: #009900;
        }
        .title2{
            tab-size: 25px;
            font-weight: 600;
        }

        input {
            width: 100%;
            height: 30px;
            padding: 10px;
        }
        .error {
            position: absolute;
            top: 255px;
            color: red;
            font-size: 15px;
        }

        .btnSubmit {
            margin-top: 30px;
            padding: 10px 20px;
            border-radius: 12px;
            color: white;
            font-weight: 600;
            background-color: #009900;
            &:hover {
                background-color: #02a202;
                cursor: pointer;
            }
        }
    }
}