.leftbar {
    flex: 1;
    position: sticky;
    top: 70px;
    height: calc(100vh - 70px);
    overflow: scroll;
    // padding: 20px;
    background-color: whitesmoke;

    &::-webkit-scrollbar {
        display: none;
    }

    .container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        
        .item {
            padding: 20px;
            border-radius: 16px;
            background-color: lightgray;
            font-weight: 600;
            &:hover {
                cursor: pointer;
                background-color: rgb(220, 220, 220);
            }
        }
    }
}